export const AppVersion = () => {
    const date = new Date(Date.parse(import.meta.env.VITE_BUILD_DATE));
    // convert to local time (EST) and make version string that is
    // YYYY-MM-DD HH:MM:SS
    // const buildDate = new Date(date).toLocaleString('en-US', {
    //     timeZone: 'America/New_York',
    //     year: 'numeric',
    //     month: '2-digit',
    //     day: '2-digit',
    //     hour: '2-digit',
    //     minute: '2-digit',
    //     hour12: false,
    // }).replaceAll('/', '.').replaceAll(':', '.').replaceAll(' ', '.').replaceAll(",", "");

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');
    return <div className="appVersion">{year}.{month}.{day}.{hour}{minute}{second} NC</div>;
}