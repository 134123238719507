import { ATRecord, Branches, Companies, Contacts, Deals, Growers, IAirtableAttachment, JobReady, Jobs, create, createInstance, getField, getFieldId, select } from '@rogoag/airtable';
import { checkIfS3FileExists, downloadFile, upload, urlFor } from './s3_ops';
import axios, { AxiosRequestConfig } from 'axios';
import { setJwtToken, setRefreshToken } from './jwt';
import { RogoPortalUser, UserContext } from '../hooks/UserContext';
import { CustomerReadyPriority, PortalConfig, JobsPriority, ViewDefinition } from '../types';
import { SHP } from '../geojson_converters/shp';
import { FeatureCollection, MultiPolygon, Polygon } from 'geojson';
import { inflate } from 'pako';
import * as Sentry from '@sentry/react';

//Airtable Imports
const airtable = createInstance(
    import.meta.env.VITE_AIRTABLE_API_KEY!,
    import.meta.env.VITE_AIRTABLE_BASE_ID!,
);

const JobsTable = airtable.table<Jobs>('Jobs');
const JobsReadyTable = airtable.table<JobReady>('Job Ready');
const DealsTable = airtable.table<Deals>('Deals');
const ContactsTable = airtable.table<Contacts>('Contacts');
const CompaniesTable = airtable.table<Companies>('Companies');
const BranchesTable = airtable.table<Branches>('Branches');
const GrowersTable = airtable.table<Growers>('Growers');

export const hasAttachments = (field: any): field is IAirtableAttachment[] => {
    console.log(field);
    // null/undefined field value 
    if (!field) return false;

    // check if the field is an array
    if (!Array.isArray(field)) return false;

    // make sure we have at least one element in the array
    if (!field.length) return false;

    // make sure this is an object, not a basic type
    if (typeof field[0] !== "object") return false;

    // make sure the first element has a url property
    if (!("url" in field[0])) return false;

    // make sure the first elements url property is truthy
    if (!field[0]["url"]) return false;

    return true;
}

// TODO seems there might be a caching issue
const ROGO_DATA_URL = import.meta.env.VITE_ROGO_API_URL 

export async function resetPassword(email: string) {
    try {
        const response = await axios.post(`${ROGO_DATA_URL}/auth/reset-password`, { email }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        Sentry.metrics.increment("password_resets", 1);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            //console.error(error.response.data);
            throw new Error(error.response.data.detail || 'Error resetting password');
        } else {
            //console.error(error);
            throw new Error('Network or other error');
        }
    }
}

export async function validateNewPasswordToken(token: string) {
    try {
        const response = await axios.post(`${ROGO_DATA_URL}/auth/validate-token`, { token }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            //console.error(error.response.data);
            throw new Error(error.response.data.detail || 'Error validating token');
        } else {
            //console.error(error);
            throw new Error('Network or other error');
        }
    }
}

export async function updatePassword({ username = '', password = '', token = ''} = {}) {
    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('username', username);
    params.append('password', password);
    params.append('scope', '');
    params.append('client_id', '');
    params.append('client_secret', token);
    try{
        const response = await axios.post<{ access_token: string, token_type: string }>(`${ROGO_DATA_URL}/auth/update-password`, params);
        return response.status === 200;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            //console.error(error.response.data);
            throw new Error(error.response.data.detail || 'Error');
        } else {
            //console.error(error);
            throw new Error('Network or other error');
        }
    }

}

export async function registerUser({ username = '', password = ''} = {}) {
    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('username', username);
    params.append('password', password);
    params.append('scope', '');
    params.append('client_id', '');
    params.append('client_secret', '');
    try{
        const response = await axios.post<{ access_token: string, token_type: string }>(`${ROGO_DATA_URL}/auth/register`, params);

        const token = response.data.access_token;
        localStorage.setItem('rogo_id_token', token);

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        const user = await getUser();
        console.log(user);

        if (!user) {
            Sentry.captureMessage(`User with username ${username} not found`);
            throw new Error(`User ${username} not found`);
        }

        setJwtToken(token);
        setRefreshToken('');

        Sentry.metrics.increment("register", 1);

        return { ...user, token, refreshToken: '' };

    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            //console.error(error.response.data);
            throw new Error(error.response.data.detail || 'Error');
        } else {
            //console.error(error);
            throw new Error('Network or other error');
        }
    }

}

interface JobReadyArgs {
    fieldPriority: CustomerReadyPriority;
    submissionNotes: string;
    selectedJobs: ATRecord<Jobs>[];
}
export async function createJobReady({ fieldPriority, submissionNotes, selectedJobs }: JobReadyArgs) {
    const jobsIds = selectedJobs.map(job => job.id);
    // @ts-ignore
    const jobReadyForm: ATRecord<JobReady> = {
        table: "Job Ready",
        [getFieldId("Job Ready", "Name")]: "",
        [getFieldId("Job Ready", "Jobs")]: jobsIds,
        [getFieldId("Job Ready", "Customer Priority")]: fieldPriority,
        [getFieldId("Job Ready", "Field Ready Submission Notes")]: submissionNotes,
        [getFieldId("Job Ready", "Submitter")]: "",
    } as const;

    console.log(jobReadyForm);

    for (let i = 0; i < selectedJobs.length; i++) { // need to test this
        const job = selectedJobs[i];
        if (!!job['Job Ready Form']) {
            Sentry.captureMessage(`Job ${job.id} already marked as ready, but an additional Job Ready form is being attached`, "info");
        }
    }

    Sentry.metrics.increment("jobs_marked_ready", selectedJobs.length);
    Sentry.metrics.distribution("jobs_marked_ready", selectedJobs.length);

    await create(JobsReadyTable, [jobReadyForm]);
}

export async function login({ username = '', password = '', token = '' } = {}) {
    if (!token) {
        const params = new URLSearchParams();
        params.append('grant_type', 'password');
        params.append('username', username);
        params.append('password', password);
        params.append('scope', '');
        params.append('client_id', '');
        params.append('client_secret', '');
        const response = await axios.post<{ access_token: string, token_type: string }>(`${ROGO_DATA_URL}/token`, params);
        token = response.data.access_token;
        localStorage.setItem('rogo_id_token', token);
    }

    // if succeeded, we will set this token as the default
    // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    // first do the user call with the token attached manually
    const user = await getUser();

    if (!user) {
        Sentry.captureMessage(`User with username ${username} not found`);
        throw new Error(`User ${username} not found`);
    }

    setJwtToken(token);
    setRefreshToken('');

    return { ...user, token, refreshToken: '' };
}

export async function createJob(record: Partial<Jobs>) {
    // iterate through all keys and find all Attachment objects
    // upload them to S3
    // replace the Attachment objects with URLs
    for (const [key, value] of Object.entries(record)) {
        if (hasAttachments(value)) {
            const urls = await Promise.all(
                value.filter(value => value.id === '').map(async (attachment) => {
                    const blob = await fetch(attachment.url).then(res => res.blob());
                    const result = await upload(attachment.filename, blob, attachment.type);
                    return result.Location;
                }));
            // const url = await upload(key, value, value.type);
            // record[key] = [{ url: url.Location }] as IAirtableAttachment[];
            record[key] = urls.filter(url => !!url).map(url => ({ url, filename: url?.split('/').pop() })) as IAirtableAttachment[];
        }
    }
    return await JobsTable.create(record);
}

export async function proagricaLoggedIn() {
    const user = await getUser();
    return !!user.proagrica_auth;
}

export async function setProagricaState(nonce: string) {
    const headers = {
        Authorization: `Bearer ${localStorage.getItem('rogo_id_token')}`
    }

    const config: AxiosRequestConfig<any> = { 
        headers,
        responseType: 'blob',
    }
    await axios.post(`${ROGO_DATA_URL}/auth/proagrica?nonce=${nonce}`, undefined, config);
}

// TODO this all needs to go through the API...
export async function getUsersData(user?: string | ATRecord<Contacts>) {
    // get /rogo/batch endpoint
    // const result = await axios.get<Blob>(`${ROGO_DATA_URL}/rogo/batch`, {
    //     headers: {
    //         Authorization: `Bearer ${localStorage.getItem('rogo_id_token')}`
    //     }
    // });

    // const data = JSON.parse(inflate(await result.data.arrayBuffer(), { to: 'string' })) as any;

    // console.log(data);
    // console.log(Object.keys(data));

    // TODO handle undefined better...
    const userRecord = typeof user === 'string' ? await _getUser(user) : user;
    if (!userRecord) {
        Sentry.captureMessage(`User not found with ID ${typeof user === 'string' ? user : user?.id} (${typeof user})`);
        

        const identifier = typeof user === 'string' ? user : user?.id;
        throw new Error(`User ${identifier || "Undefined"} not found`);
    }
    const companyIDs = getField(userRecord, 'Company', []).concat(getField(userRecord, 'DM for', []));

    if (companyIDs.length === 0) throw new Error(`User must be linked to at least one company`);

    const formula = `OR(${companyIDs.map((id) => `RECORD_ID()='${id}'`).join(',')})`;

    const companyRecords = await select(CompaniesTable, {
        filterByFormula: formula,
        returnFieldsByFieldId: true,
    });

    // if (companyRecords.length != 1) throw new Error(`User must be linked to exactly one company`);

    const allCompanies: ATRecord<Companies>[] = companyRecords;

    const usersCompany = allCompanies[0];

    // TODO if superuser (ROGO org user...) then get all companies?
    const userCompanyName = getField(usersCompany, 'Client Clean');
    if (!userCompanyName) throw new Error('Company name not found');

    const importOptions: PortalConfig[] = ['Single Job Upload']

    // Handsoff Files: Rogo Form + Files Retrieve
    const atConfigItems: string[] = getField(usersCompany, 'Portal Configuration Options') || [];
    if (atConfigItems.includes('Import: CSV + SHP')) {
        importOptions.push('CSV + SHP Upload');
    }
    
    // @ts-ignore
    const companySoftwareActive: string[] = getField(usersCompany, 'Softwares Active');
    const hasProAgrica = 
        getField(userRecord, "PAuth") || 
        (companySoftwareActive.length > 0 && companySoftwareActive[0]?.toLowerCase().includes("proagrica")) ||
        atConfigItems.includes("Import: ProAgrica");

    if (userCompanyName.toLowerCase() === 'rogo ag') {
        importOptions.push(
            'CSV + SHP Upload', 
            'ProAgrica/Agx Import'
        );
        allCompanies.push(...await select(CompaniesTable, {
            view: `viwoHc77Fa4haA7TT`,
            //returnFieldsByFieldId: true,
        }));
    } else if (hasProAgrica) {
        importOptions.push('ProAgrica/Agx Import');
    }

    // remove duplicates based on airtable ID
    const uniqueCompanies = allCompanies.filter((company, index, self) =>
        index === self.findIndex((t) => (
            t.id === company.id
        ))
    );

    // return the primary companies deals by default
    const dealIds = getField(usersCompany, '#Deals') || [];
    if (dealIds.length === 0) throw new Error('Company must have at least one deal');
    const deals = await select(DealsTable, {
        filterByFormula: `OR(${dealIds.map((id) => `AND(RECORD_ID()='${id}', {Active}=TRUE())`).join(',')})`,
        returnFieldsByFieldId: true,
    });
    
    return [userRecord, uniqueCompanies, deals, importOptions, usersCompany] as const;
}

type PortalSettings = {
    views: ViewDefinition[];
}

export async function updateUserPortalSettings(user: string | ATRecord<Contacts>, portalSettings: PortalSettings) {
    const userRecord = typeof user === 'string' ? await _getUser(user) : user;
    const updatedRecord = await ContactsTable.update(userRecord.id, {
        "Portal Settings": JSON.stringify(portalSettings)
    });
    Sentry.metrics.increment("update_user_portal_settings", 1);
    Sentry.metrics.distribution("user_portal_views", portalSettings.views.length);
    return updatedRecord;
}

export async function updateCompanyPortalViews(company: string | ATRecord<Companies>, views: ViewDefinition[]) {
    const companyRecord = typeof company === 'string' ? await getCompany(company) : company;
    const updatedRecord = await CompaniesTable.update(companyRecord.id, {
        "Portal Views": JSON.stringify(views)
    });
    Sentry.metrics.increment("update_company_portal_settings", 1);
    Sentry.metrics.distribution("user_portal_views", views.length);
    return updatedRecord;
}

// TODO this all needs to go through the API...
export async function getBranches(company: string | ATRecord<Companies>) {
    const companyRecord = typeof company === 'string' ? await getCompany(company) : company;
    const branchIds = getField(companyRecord, 'Locations/Branches');
    if (!branchIds || branchIds.length === 0) return [];
    const branches = await select(BranchesTable, {
        filterByFormula: `OR(${branchIds.map((id) => `RECORD_ID()='${id}'`).join(',')})`,
        returnFieldsByFieldId: true,
    });
    Sentry.metrics.distribution("branches_fetched", branches.length);
    return branches;
}

export async function getCompany(companyId: string) {
    const companies = await select(CompaniesTable, {
        filterByFormula: `RECORD_ID()='${companyId}'`,
        returnFieldsByFieldId: true,
    });

    if (companies.length != 1) throw new Error(`Company ${companyId} not found`);

    return companies[0];
}

export async function getGrowersFromClient(client: string | ATRecord<Companies>) {
    const clientRecord = typeof client === 'string' ? await getCompany(client) : client;
    const growerIds = getField(clientRecord, 'Growers');
    const growers = await select(GrowersTable, {
        filterByFormula: `{${getFieldId('Growers', 'Clients Unique')}}='${getField(clientRecord, 'Client Clean')}'`,
    });
    Sentry.metrics.distribution("growers_fetched", growers.length);
    return growers;
}

export async function getDealsFromClient(client: string | ATRecord<Companies>) {
    const clientRecord = typeof client === 'string' ? await getCompany(client) : client;
    const dealIds = getField(clientRecord, '#Deals') || [];
    if (dealIds.length === 0) return [];
    const deals = await select(DealsTable, {
        filterByFormula: `AND({Active}=TRUE(),OR(${dealIds.map((id) => `RECORD_ID()='${id}'`).join(',')}))`,
        returnFieldsByFieldId: true,
    });
    return deals;
}

export async function downloadATAttachment<T=ArrayBuffer|Blob|string>(
    recordID: string, 
    attachments: IAirtableAttachment[],
    responseType: 'arraybuffer' | 'blob' | 'json' | 'text'
) {
    try {
        if (!attachments || !attachments.length) {
            return;
        }
    
        const attachment = attachments[0];
    
        // first try to form an S3 url
        let filename = attachment.filename;
        // filename will be in two different formats
        // {recordID}/{filename} or {filename}
        if (filename.startsWith(`${recordID}/`)) {
            // remove the recordID from the filename
            filename = filename.split('/').slice(1).join('/');
        }
    
        const s3Url = urlFor(filename);
        if (await checkIfS3FileExists(s3Url)) {
            return await downloadFile<T>(s3Url, responseType);
        }
    
        // if that fails, we will try to download the attachment directly
        return await downloadFile<T>(attachment.url, responseType);
    } catch (ex) {
        console.error(ex);
    }
}

export async function getBoundaryForJob(job: ATRecord<Jobs>) {
    const bndGeoJSON = await downloadATAttachment<string>(job.id, getField(job, 'Bnd GeoJSON'), 'json');

    if (bndGeoJSON) {
        // console.count('Got boundary from GeoJSON');
        const bndGeoJSONParsed = bndGeoJSON as unknown;
        return bndGeoJSON as unknown as FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>;
    }

    const bndShp = await downloadATAttachment<ArrayBuffer>(job.id, getField(job, 'Bnd Shp'), 'arraybuffer');

    if (bndShp) {
        try {
            // console.log('Got boundary from SHP');
            return await SHP.toGeoJSON(Buffer.from(bndShp)) as unknown as FeatureCollection<Polygon> | FeatureCollection<MultiPolygon>;
        } catch (ex) {
            console.error(ex);
        }
    }
}

export async function getPointsForJob(job: ATRecord<Jobs>) {
    const exePtsShp = await downloadATAttachment<ArrayBuffer>(job.id, getField(job, 'Exe Pts Shp'), 'arraybuffer');
    if (exePtsShp) {
        try {
            return await SHP.toGeoJSON(Buffer.from(exePtsShp)) as unknown as FeatureCollection<Polygon>;
        } catch (ex) {
            console.error(ex);
        }
    }

    const ptsShp = await downloadATAttachment<ArrayBuffer>(job.id, getField(job, 'Pts Shp'), 'arraybuffer');
    if (ptsShp) {
        try {
            return await SHP.toGeoJSON(Buffer.from(ptsShp)) as unknown as FeatureCollection<Polygon>;
        } catch (ex) {
            console.error(ex);
        }
    }
}

export async function getUnreadyJobs(company?: ATRecord<Companies>): Promise<ATRecord<Jobs>[]> {
    const headers = {
        Authorization: `Bearer ${localStorage.getItem('rogo_id_token')}`
    }

    const config: AxiosRequestConfig<any> = { 
        headers,
        responseType: 'blob',
    }

    // make API call to localhost:8000/jobs
    let url = `${ROGO_DATA_URL}/rogo/jobs/unready`;
    if (company) {
        url += `?company=${company.id}`;
    }

    try {
        const result = await axios.get<Blob>(url, config);
        const buffer = await result.data.arrayBuffer();
        const jobs = JSON.parse(inflate(buffer, { to: 'string' })) as ATRecord<Jobs>[];
        Sentry.metrics.distribution("jobs_fetched_payload_size", buffer.byteLength);
        Sentry.metrics.distribution("jobs_fetched", jobs.length);
        Sentry.metrics.increment("/rogo/jobs/unready called", 1);
        return jobs;
    } catch (err) {
        console.error(err);
        return [];
    }
}

export async function getJobs(company?: ATRecord<Companies>): Promise<ATRecord<Jobs>[]> {
    // TODO need to redirect to portal API once we deal with return limit
    // return await select(JobsTable, {
    //     //     jobs = find_records("Jobs", f"FIND('{company_name}', Client)")
    //     filterByFormula: `FIND('${getField(company, 'Name')}', {Client})>0`,
    //     fields: [ "Grower", "Field Name Clean", "Farm Name Clean", "Job Status - Simple", "Boundary Acres", "Field Ready Date", "Sample Date", "Creation Date with Time", "Drop/Ship Date", "Test Pckg - Display", "Edits by Customer", "Edit Files Complete Date (latest)", "Submitter", "$ Approved Billing?", "Base Price Subtotal - 1st Entity #$ Prim", "Total Addons Subtotal #$ Calc", "$/Ac Cost - 1st Entity", "Addons Text (Adjustments)", "Acres Short of Commit for Company", "Credit Left $ for Company", "Bnd GeoJSON", "Bnd Shp", "Pts Shp", "Sample Zones Shp", "Exe Pts Shp", "Exe Bnd Shp", "Exe Pts + Bnd Shps", "Lab Results Sent Date", "Lab Results"],
    //     returnFieldsByFieldId: true,
    // })

    const headers = {
        Authorization: `Bearer ${localStorage.getItem('rogo_id_token')}`
    }

    const config: AxiosRequestConfig<any> = { 
        headers,
        responseType: 'blob',
    }

    // make API call to localhost:8000/jobs
    let url = `${ROGO_DATA_URL}/rogo/jobs`;
    if (company) {
        url += `?company=${company.id}`;
    }

    try {
        const result = await axios.get<Blob>(url, config);
        const buffer = await result.data.arrayBuffer();
        const jobs = JSON.parse(inflate(buffer, { to: 'string' })) as ATRecord<Jobs>[];
        Sentry.metrics.distribution("jobs_fetched_payload_size", buffer.byteLength);
        Sentry.metrics.distribution("jobs_fetched", jobs.length);
        Sentry.metrics.increment("/rogo/jobs called", 1);
        return jobs;
    } catch (err) {
        console.error(err);
        return [];
    }
}

export async function getJob(jobId: string): Promise<ATRecord<Jobs> | null> {
    // Set up headers with the authorization token
    const headers = {
        Authorization: `Bearer ${localStorage.getItem('rogo_id_token')}`,
    };

    const config: AxiosRequestConfig<any> = {
        headers,
        responseType: 'blob', // Expecting a binary response
    };

    // Construct the URL for the job endpoint
    const url = `${ROGO_DATA_URL}/rogo/job?job_id=${jobId}`;

    try {
        // Make the API call
        const result = await axios.get<Blob>(url, config);

        // Decompress the response data
        const decompressedData = inflate(await result.data.arrayBuffer(), { to: 'string' });

        // Parse the decompressed data as JSON and ensure it's an object, not an array
        const job = JSON.parse(decompressedData)[0] as ATRecord<Jobs>;

        // Return the job record
        return job;
    } catch (err) {
        console.error(err);
        return null;
    }
}

export async function _getUser(userId: string) {
    const contacts = await select(ContactsTable, {
        filterByFormula: `RECORD_ID()='${userId}'`,
        returnFieldsByFieldId: true,
    });
    return contacts[0];
}

export async function getUser() {
    // call api/users/me
    const headers = {
        Authorization: `Bearer ${localStorage.getItem('rogo_id_token')}`
    }
    const user = await axios.get<RogoPortalUser>(`${ROGO_DATA_URL}/auth/me?use_cache=false`, { headers });
    // TODO if we are going to store these here we should also use expiring cookies 
    localStorage.setItem('proagrica_access_token', user.data.proagrica_auth?.access_token || '');
    localStorage.setItem('proagrica_refresh_token', user.data.proagrica_auth?.refresh_token || '');
    localStorage.setItem('proagrica_auth', JSON.stringify(user.data.proagrica_auth));
    return { ...user.data, token: localStorage.getItem('rogo_id_token')! };
}

export async function updateRecord(table: string, field: string, value: any, recordId: string) {
    try {
        const token = localStorage.getItem('rogo_id_token');
        if (!token) {
            throw new Error('Authorization token not found');
        }

        // Set the authorization header
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        // Make the PUT request to the update-record endpoint
        const response = await axios.put(`${ROGO_DATA_URL}/rogo/update-record`, null, {
            params: {
                table: table,
                field: field,
                value: value,
                record_id: recordId
            },
            headers: {
                'accept': 'application/json'
            }
        });

        console.log(response.data);

        return response.data;

    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.detail || 'Error updating record');
        } else {
            // Handle unknown errors
            throw new Error('Network or other error');
        }
    }
}
