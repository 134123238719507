import {
    Box,
    Button,
    Typography,
    Grid,
    Card,
    Accordion
} from "@mui/material";
import AccordianSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ATRecord, getField, Deals, Branches, Companies, select } from "@rogoag/airtable";
import JSZip from "jszip";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Importer, ImporterField } from "react-csv-importer";
import { toast } from "react-toastify";
import { CSVImportRows, GeoFileType, JobCreationData, KeyedShapefile, KeyedShapefileMap, RogoFeatures } from "../types";
import { ListOfFiles } from "./ListOfFiles";
import { UserContext } from "../hooks/UserContext";
import { SHP } from "../geojson_converters/shp";
import { KeyGenerationSelector } from "./KeyGenerationSelector";
import { flattenArrayObjects, groupFeatures, sleep } from "../utils";
import JobImportTable from "./JobImportTable";
import { useDropzone } from 'react-dropzone'
import { styled } from '@mui/material/styles';
import { read, utils, WorkSheet } from 'xlsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FeatureCollection } from "geojson";

const fgColor = '#000';
const fillColor = '#f0f0f0';
const borderRadius = '0.4em';
const textColor = '#202020';

function darken(color: string, percentage: number) {
    const num = parseInt(color.replace('#', ''), 16),
        amt = Math.round(2.55 * percentage),
        R = (num >> 16) - amt,
        B = ((num >> 8) & 0x00FF) - amt,
        G = (num & 0x0000FF) - amt;
    return "#" + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1);
}

const CSVImporter_FileSelector = styled('div')(({ theme }) => ({
    border: `0.25em dashed ${fgColor}`,
    padding: '4em',
    borderRadius: borderRadius,
    background: fillColor,
    textAlign: 'center',
    color: textColor,
    cursor: 'pointer',
    '&[data-active="true"]': {
        background: darken(fillColor, 10),
        transition: 'background 0.1s ease-out',
    },
}));

async function processShapeFiles(files: File[]) {
    const fileMap: Record<string, File[]> = {};
    async function processDir(dir: JSZip.JSZipObject, path: string) {
        console.log('processDir', path, dir.name);
    }
    // recursively search all directories for relevant shapes, folders, or files
    async function processZip(file: File, path: string) {
        const zip = await JSZip.loadAsync(file);
        await Promise.all(Object.keys(zip.files).map(async (key) => {
            const zipEntry = zip.file(key);
            if (!zipEntry) return;

            const entryName = zipEntry.name;

            if (entryName.includes("__MACOSX")) return;

            if (zipEntry.dir) {
                processDir(zipEntry, path);
            } else if (
                entryName.endsWith('.shp') ||
                entryName.endsWith('.shx') ||
                entryName.endsWith('.dbf') ||
                entryName.endsWith('.prj')
            ) {
                // strip extension from path
                const lastPeriod = entryName.lastIndexOf('.');
                const filenameWithoutExtension = entryName.substring(0, lastPeriod);
                // console.log('filenameWithoutExtension', filenameWithoutExtension);
                const fullPath = `${path}/${filenameWithoutExtension}`;
                if (!fileMap[fullPath]) {
                    fileMap[fullPath] = [];
                }
                fileMap[fullPath].push(new File([await zipEntry.async('blob')], entryName));
            } else if (entryName.endsWith('.zip')) {
                await processZip(new File([await zipEntry.async('blob')], entryName), `${path}/${entryName}`);
            }
        }));
    }
    for (const file of files) {
        console.log(`Processing ${file.name}`, fileMap);
        if (file.name.endsWith(".zip")) {
            await processZip(file, file.name);
        } // TODO other formats?
    }

    console.log('fileMap', fileMap);

    return fileMap;

    // if (event.target.files) {
    //     // @ts-ignore

    //     //setBoundaryFiles([...event.target.files]);
    // }
}


interface CSVImportFormProps {
    selectedDeals: ATRecord<Deals>[];
    selectedBranches: ATRecord<Branches>[];
    selectedCompany: ATRecord<Companies> | null;
    setSelectableFiles: (groupKey: string, selectableFiles: File[]) => void;
    deals: ATRecord<Deals>[];
}

export function CSVImportForm(csvProps: CSVImportFormProps) {
    console.log('CSVImportForm render');
    const userContext = useContext(UserContext);
    const [transformedKeyedShapefileMap, setTransformedKeyedShapefileMap] = useState<KeyedShapefileMap>(new Map());
    const [loading, setLoading] = useState(false);
    const [jobData, setJobData] = useState<JobCreationData[]>([]);
    const [selectableFiles, setSelectableFiles] = useState<Record<string, Record<GeoFileType, File[]>>>({});
    const [originalShapefiles, setOriginalShapefiles] = useState<File[]>([]);
    const [keyedShapefileMap, setKeyedShapefileMap] = useState<KeyedShapefileMap>(new Map());
    // TODO currently set to 3rd party to use the importer, would like to go back and add auto import ability
    const [csvImportType, setCsvImportType] = useState<'ROGO' | '3rdParty'>('3rdParty');



    const handledataHandler = async (rows: CSVImportRows[]) => {
        const results: JobCreationData[] = [];

        const deal = csvProps.selectedDeals[0];
        // const dealLabAccountNumber = getRecordField(deal, 'Lab Acct #');
        // let jobAccoutNumber = dealLabAccountNumber;
        // if (dealLabAccountNumber.toLowerCase().includes('branch')) {
        //     const branch = _growerBranchMap[grower.ID];
        //     if (branch) {
        //         const branchLabAccountNumber = getRecordField(branch, 'Lab Account #');
        //         if (branchLabAccountNumber) {
        //             console.log('Branch Lab Account Number:', branchLabAccountNumber);
        //             jobAccoutNumber = branchLabAccountNumber;
        //         }
        //     }
        // }
        let ignoredRows = 0;

        for (const row of rows) {
            // checks if row is empty
            if (rows.length === 1) {
                const row = rows[0];
                if ((row['growerName'] === undefined) || row['growerName'].includes('\n') || row['growerName'] === '') {
                    ignoredRows++;
                    break;
                }
                if ((row['fieldName'] === undefined) || row['fieldName'].includes('\n') || row['fieldName'] === '') {
                    ignoredRows++;
                    break;
                }
            }

            console.log('row', row);
            if (!row.growerName && !row.fieldName) {
                ignoredRows++;
                continue;
            }


            // 2.5grid_RO+Zn_8in
            let dealId = row.deal ? undefined : deal.id;
            //let [densityAndType, testPackage, depthString] = row.deal?.split('_') || [undefined, undefined, undefined];
            let density = row.gridSize ? parseFloat(row.gridSize) : getField(deal, "Density/Grid #serviceinfo");
            const type = getField(deal, "Sites Type #serviceinfo");
            const testPackage = row.testPackage || getField(deal, 'Test Pckg Display') as string;
            const depth = row.depth ? parseFloat(row.depth) : getField(deal, 'Depth (in.) #serviceinfo');
            const micros = row.freqOfMicros ? parseFloat(row.freqOfMicros) : getField(deal, 'Freq of Add-Ons #Every Nth Sample  #bill');
            console.log(micros, "micros")

            // depth: getRecordField(deal, "Depth (in.) #serviceinfo"),
            // deal: deal.id,
            // gridSize: getRecordField(deal, "Density/Grid #serviceinfo"),
            // growerExternalId: grower.ID,
            // labAccountNumber: jobAccoutNumber,
            // labPackage: getRecordField(deal, 'Test Pckg Display') as string,
            results.push({
                ...row,
                // TODO job flags
                abPlantingLine: 0,
                boundary: [],
                boundaryGeoJSON: undefined,
                branchId: csvProps.selectedBranches.length ? csvProps.selectedBranches[0].id : '',
                collectBoundary: false,
                companyRecordId: csvProps.selectedCompany?.id || '',
                deal: dealId,
                depth: depth || 0,
                externalId: '',
                fieldGuid: row.fieldGuid || '',
                fieldNotes: [],
                fieldPriority: 'Regular Turn (default)',
                frequencyOfMicros: micros || null,
                gridSize: density || 0,
                growerCell: row.growerCell || '',
                growerEmail: '',
                growerRecord: null,
                growerRecordId: undefined,
                jobFlags: [row.jobFlags || 'Bulk Upload'],
                labPackage: testPackage,
                labPresubmissionCode: row.regularLabSubmissionCode || '',
                lastCrop: row.lastCrop,
                nextCrop: row.nextCrop,
                optionalFieldAttachments: [],
                points: [],
                pointsAttached: ["y", "yes", "true"].includes(row.pointsAttached?.toLowerCase() || "No"),
                readyToSampleNow: ["y", "yes", "true"].includes(row.ready?.toLowerCase() || "No"),
                recordBoundary: false,
                rogoId: '',
                selectedEvent: null,
                sendGrowerSamplingNotification: false,
                specialLabInstructions: row.specialLabInstructions || '',
                specsFromForm: row.specsFromForm,
                submitterEmail: row.submitterEmail ?? userContext.email,
                submitterName: row.submitterName ?? userContext.full_name,
                syncCode: row.syncCode,
                syncCodeReadonly: false,
                temporaryId: Math.random().toString(36).substring(7),
                zones: [],
            })
        }

        // checks if data should be added to jobData
        if (results.length != 0) {
            setJobData(prevData => [...prevData, ...results]);
            console.log('after setJbData', jobData)

            toast.success(`Parsed ${results.length} rows`);
        }

        if (ignoredRows > 0) {
            toast.warn(`Ignored ${ignoredRows} rows with no grower or field name`);
        }
    };

    function CSVDropzone() {
        const onDrop = useCallback(async (acceptedFiles: File[]) => {
            // Do something with the files
            // iterate through all files
            for (const file of acceptedFiles) {
                const wb = read(await file.arrayBuffer());

                let selectedSheet: WorkSheet | null = null;
                let seletedSheetName: string | null = null;
                for (const sheetName of wb.SheetNames) {
                    if (wb.SheetNames.length === 1 || sheetName.toLowerCase().includes('csv download tab')) {
                        selectedSheet = wb.Sheets[sheetName];
                        seletedSheetName = sheetName;
                        break;
                    }
                }

                if (!selectedSheet) {
                    toast.error('Unable to determine correct sheet');
                    return;
                }

                const rows = utils.sheet_to_json(selectedSheet);

                console.log(seletedSheetName, selectedSheet, rows);
            }
        }, [])
        const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

        return (
            <CSVImporter_FileSelector {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>Drop the files here ...</p> :
                        <p>Drag 'n' drop some files here, or click to select files</p>
                }
            </CSVImporter_FileSelector>
        )
    }

    async function investigateGroupKeyfiles(groupKey: string, tokens: string[]) {
        setLoading(true);
        // get existing map
        const uniqueKeyedShapefileMap = new Map(transformedKeyedShapefileMap || keyedShapefileMap);
        await sleep(3000);
        setLoading(false);
    }
    return <>
        <Grid
            alignItems="stretch"
            alignContent="stretch"
            container
            maxWidth="md"
            margin="auto"
            spacing={1}
        >
            {loading && <Grid item xs={12}>
                <Typography>Loading...</Typography>
            </Grid>}
            {/* <Grid item xs={12} width={'100%'}>
                <FormControl>
                    <Box display={'flex'}>
                        <FormLabel style={{ marginTop: 9, marginRight: 20 }}>Select CSV Type</FormLabel>
                        <RadioGroup
                            defaultValue="ROGO"
                            row
                            onChange={(event) => {
                                console.log(event);
                                // @ts-ignore
                                setCsvImportType(event.target.value);
                            }}
                        >
                            <FormControlLabel value="ROGO" control={<Radio />} label="ROGO CSV/XLSX" />
                            <FormControlLabel value="3rdParty" control={<Radio />} label="3rd Party CSV" />
                        </RadioGroup>
                    </Box>
                </FormControl>
            </Grid> */}
            <Grid item xs={12} width={'100%'}>

                {csvImportType === '3rdParty' && <Importer<CSVImportRows>
                    skipEmptyLines={false}
                    /*dataHandler={async (rows) => {
                        // required, may be called several times
                        // receives a list of parsed objects based on defined fields and user column mapping;
                        }*/
                    dataHandler={handledataHandler}
                    defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
                    restartable={true} // optional, lets user choose to upload another file when import is complete
                    onStart={({ file, preview, fields, columnFields }) => {
                        // optional, invoked when user has mapped columns and started import
                        console.log('Start', file, preview, fields, columnFields);
                    }}
                    onComplete={({ file, preview, fields, columnFields }) => {
                        // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
                        console.log('Complete', file, preview, fields, columnFields);
                    }}


                    // displayFieldRowSize={5}
                    // onClose={({ file, preview, fields, columnFields }) => {
                    //     // optional, if this is specified the user will see a "Finish" button after import is done,
                    //     // which will call this when clicked
                    //     console.log('Close', file, preview, fields, columnFields);
                    // }}

                    // CSV options passed directly to PapaParse if specified:
                    // delimiter={','}
                    // newline={false}
                    // quoteChar={...}
                    // escapeChar={...}
                    // comments={...}
                    //delimitersToGuess={...}
                    // @ts-ignore
                    chunkSize={10}
                // encoding={...} // defaults to utf-8, see FileReader API
                >
                    <ImporterField name="growerName" label="Grower from Form" />
                    <ImporterField name="growerEmail" label="Grower Email" optional />
                    <ImporterField name="growerCell" label="Grower Cell Phone Number" optional />
                    <ImporterField name="fieldGuid" label="Field GUID" optional />
                    <ImporterField name="fieldName" label="Field from Form" />
                    <ImporterField name="farmName" label="Farm from Form" optional />
                    <ImporterField name="syncCode" label="Event ID" optional />
                    <ImporterField name="locationFromForm" label="Location from Form" optional />
                    <ImporterField name="depth" label="Depth from Form" optional />
                    <ImporterField name="labFromForm" label="Lab Name" optional />
                    <ImporterField name="gridSize" label="Grid from Form" optional />
                    <ImporterField name="testPackage" label="3rd Party Lab Test Package ID" optional />
                    <ImporterField name="ready" label="Field Ready at Submission?" optional />
                    <ImporterField name="fieldPriority" label="Priority OVRD" optional />
                    <ImporterField name="submissionNotes" label="Submission Notes" optional />
                    <ImporterField name="labAccountNumber" label="Lab Account Number" optional />
                    <ImporterField name="freqOfMicros" label="Frequency of Micros" optional />
                    <ImporterField name="submitterName" label="Submitter" optional />
                    <ImporterField name="submitterEmail" label="Submitter Email" optional />
                    <ImporterField name="pointsAttached" label="Retest? (Pts Submitted Points Attached?" optional />
                    <ImporterField name="boundaryChange" label="Boundary Change Type" optional />
                    <ImporterField name="jobFlags" label="Job Flags" optional />
                    <ImporterField name="specialLabInstructions" label="Special Lab Instructions" optional />
                    <ImporterField name="nextCrop" label="Next Crop" optional />
                    <ImporterField name="lastCrop" label="Last Crop" optional />
                    <ImporterField name="specsFromForm" label="Specs from Form" optional />
                    <ImporterField name="regularLabSubmissionCode" label="Regular Lab Submission Code" optional />

                    {/* <ImporterField name="growerName" label="Grower Name" />
                    <ImporterField name="growerEmail" label="Grower Email" optional />
                    <ImporterField name="growerCell" label="Grower Cell Phone" optional />
                    <ImporterField name="fieldGuid" label="Field GUID" optional />
                    <ImporterField name="fieldName" label="Field Name" />
                    <ImporterField name="farmName" label="Farm Name" optional />
                    <ImporterField name="syncCode" label="Event/Layer ID" optional />
                    <ImporterField name="locationFromForm" label="Branch Location" optional />
                    <ImporterField name="depth" label="Depth" optional />
                    <ImporterField name="labFromForm" label="Lab Name" optional />
                    <ImporterField name="gridSize" label="Grid Size / Density" optional />
                    <ImporterField name="testPackage" label="Lab Test Package" optional />
                    <ImporterField name="ready" label="Field Ready?" optional />
                    <ImporterField name="fieldPriority" label="Priority" optional />
                    <ImporterField name="submissionNotes" label="Submission Notes" optional />
                    <ImporterField name="labAccountNumber" label="Lab Account Number" optional />
                    <ImporterField name="freqOfMicros" label="Frequency of Micros" optional />
                    <ImporterField name="submitterName" label="Submitter" optional />
                    <ImporterField name="submitterEmail" label="Submitter Email" optional />
                    <ImporterField name="pointsAttached" label="Points Attached?" optional />
                    <ImporterField name="boundaryChange" label="Boundary Change Type" optional />
                    <ImporterField name="jobFlags" label="Job Flags" optional /> */}
                </Importer>}

                {csvImportType === 'ROGO' &&
                    <CSVDropzone />}
                {/* <Dropzone onDrop={acceptedFiles => { */}
                {/* console.log(acceptedFiles[0].name); */}
                {/* }}> */}
                {/* {({ getRootProps, getInputProps }) => ( */}
                {/* <section> */}
                {/* <CSVImporter_FileSelector {...getRootProps()}> */}
                {/* <input {...getInputProps()} /> */}
                {/* <p>Drag 'n' drop some files here, or click to select files</p> */}
                {/* </CSVImporter_FileSelector> */}
                {/* </section> */}
                {/* )} */}
                {/* </Dropzone>} */}
            </Grid>
            {/* <CSVMatcher columnsToMatch={["a", "b", "c"]} csvData={[["x", "y", "z"]]} ></CSVMatcher> */}
            {jobData.length > 0 &&
                <Button style={{ width: '100%', marginTop: 10, marginLeft: 8, marginBottom: 10 }} variant='contained' onClick={() => {
                    setTransformedKeyedShapefileMap(new Map());
                    setLoading(false);
                    setJobData([]);
                    setSelectableFiles({});
                    setOriginalShapefiles([]);
                    setKeyedShapefileMap(new Map());
                    setCsvImportType('3rdParty');
                }}>
                    Remove CSV file(s)
                </Button>
            }
            {jobData.length > 0 &&
                <Grid item xs={12}>
                    <Box display={'flex'}>
                        <Button style={{ width: '80%', marginRight: 5 }} variant='contained' onClick={async () => {
                            const loadingFiles = toast.loading("Loading files...", { closeButton: true })
                            try {
                                // show file dialog
                                const fileInput = document.createElement('input');
                                fileInput.type = 'file';
                                fileInput.accept = '.zip';
                                fileInput.multiple = true;
                                fileInput.onchange = (event) => {
                                    event.preventDefault();
                                    setLoading(true);
                                    // @ts-ignore
                                    const newFiles: File[] = Array.from(event.target.files);
                                    const oldFiles = [...originalShapefiles];
                                    for (const newFile of newFiles) {
                                        if (oldFiles.find(oldFile => oldFile.name === newFile.name)) {
                                            // remove from oldFiles
                                            const index = oldFiles.findIndex(oldFile => oldFile.name === newFile.name);
                                            oldFiles.splice(index, 1);
                                        }
                                    }

                                    const allFiles = [...oldFiles, ...newFiles];

                                    const asyncProcessing = async () => {
                                        const fileMap = await processShapeFiles(allFiles);

                                        setOriginalShapefiles(allFiles);

                                        const features: RogoFeatures[] = [];
                                        const featureCollections: FeatureCollection[] = [];
                                        const keyedShapefiles = await Promise.all(Object.keys(fileMap).map(async fullPath => {

                                            const shapefiles = fileMap[fullPath];
                                            // zip shapefiles into single file
                                            const zip = new JSZip();
                                            shapefiles.forEach((file, index) => {
                                                console.log('Adding file', file.name);
                                                zip.file(file.name, file);
                                            });
                                            if (!fullPath.endsWith('.zip')) {
                                                fullPath = fullPath + '.zip';
                                            }
                                            const shpBuffer = await zip.generateAsync({ type: "nodebuffer" });
                                            const geoJSON = await SHP.toGeoJSON(shpBuffer);
                                            featureCollections.push(geoJSON);
                                            // @ts-ignore
                                            const shapeCounts: Record<string, number> = {};

                                            if (!geoJSON.features.length) {
                                                return [fullPath, null] as const;
                                            }

                                            geoJSON.features.forEach(feature => {
                                                // @ts-ignore
                                                features.push(feature);
                                                const type = feature.geometry.type;
                                                if (!shapeCounts[type]) {
                                                    shapeCounts[type] = 0;
                                                }
                                                shapeCounts[type]++;
                                            });
                                            // console.log(shapeCounts);
                                            const shpProperties: Record<string, string> = { ...geoJSON.features[0].properties }
                                            // @ts-ignore
                                            const dbfKeys = Object.keys(shpProperties).sort();
                                            const folderLevels = fullPath.split('/');
                                            const types = Object.keys(shapeCounts).sort();
                                            const groupKey = `${folderLevels.length},${dbfKeys.join(',')},${types.join(",")}`;
                                            const file = new File([shpBuffer], fullPath);
                                            const keyedFile: KeyedShapefile = file as KeyedShapefile;
                                            keyedFile.geoJSON = geoJSON;
                                            keyedFile.dbfKeys = dbfKeys;
                                            keyedFile.dbfValues = Object.values(shpProperties);
                                            keyedFile.geoType = 'Unknown';
                                            keyedFile.path = fullPath;
                                            keyedFile.shapeCounts = shapeCounts;

                                            // & {
                                            //     groupKey,
                                            //     dbfKeys,
                                            //     dbfValues: dbfKeys.map(key => shpProperties[key]),
                                            //     geoType: "Unknown",
                                            //     path: fullPath,
                                            //     geoJSON,
                                            //     shapeCounts
                                            // };
                                            // console.log(keyedFile);
                                            return [groupKey, keyedFile] as const;
                                        }));

                                        // const blob = await groupFeatures(featureCollections);
                                        // // download blob
                                        // const url = URL.createObjectURL(blob);
                                        // const a = document.createElement('a');
                                        // a.href = url;
                                        // a.download = `all_features_${Date.now()}.zip`;
                                        // a.click();

                                        const newKeyedShapefiles: Map<string, KeyedShapefile[]> = new Map();

                                        for (const [groupKey, keyedShapefile] of keyedShapefiles) {
                                            if (!keyedShapefile) continue;

                                            if (!newKeyedShapefiles.has(groupKey)) {
                                                newKeyedShapefiles.set(groupKey, []);
                                            }
                                            newKeyedShapefiles.get(groupKey)?.push(keyedShapefile);
                                        }

                                        setKeyedShapefileMap(newKeyedShapefiles);

                                        setLoading(false);
                                        toast.dismiss(loadingFiles);
                                    }

                                    try {
                                        
                                    } catch (error) {
                                        console.error(error);
                                        toast.error("Error processing files");
                                    }

                                    asyncProcessing();
                                };
                                fileInput.onclose = () => {
                                    console.log(fileInput.files);
                                };
                                fileInput.click();
                            } catch (error) {
                                console.error(error);
                                toast.error("Error processing files");
                                toast.dismiss(loadingFiles);
                            }

                        }}>
                            Add Shapefiles
                        </Button>
                        <Button style={{ width: '20%' }} variant='contained' onClick={async () => {
                            setKeyedShapefileMap(new Map());
                            setOriginalShapefiles([]);
                        }}>
                            Clear
                        </Button>
                        {/* UploadTypes */}
                    </Box>
                </Grid>
            }
            {!!originalShapefiles.length &&
                <Grid item xs={12}>
                    <Accordion defaultExpanded={true}>
                        <AccordianSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant='h5'>{`Original Files (${originalShapefiles.length} uploaded)`}</Typography>
                        </AccordianSummary>
                        <AccordionDetails>
                            <ListOfFiles files={originalShapefiles} truncate={3} onClear={(file: File) => {
                                const newFiles = [...originalShapefiles];
                                const index = newFiles.findIndex(f => f.name === file.name);
                                newFiles.splice(index, 1);
                                setOriginalShapefiles(newFiles);
                            }} />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            }
            {!!originalShapefiles.length && <Grid item xs={12}>
                <Accordion defaultExpanded={true}>
                    <AccordianSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography variant='h5'>File Tagging and Splitting</Typography>
                    </AccordianSummary>
                    <AccordionDetails>
                        {[...keyedShapefileMap.keys()].map((groupKey, index) => {
                            const shapefiles = keyedShapefileMap.get(groupKey);
                            if (!shapefiles) return;
                            const dbfKeys = shapefiles[0].dbfKeys;
                            const folderTokens = shapefiles[0].path.split('/');
                            const folderOptions: string[] = [];
                            const optionTooltips: string[][] = [];
                            for (let folderLevel = 1; folderLevel <= folderTokens.length; folderLevel++) {
                                folderOptions.push("Folder Level " + folderLevel);
                                const tooltip = shapefiles.map(shapefile => {
                                    const tokens = shapefile.path.split('/');
                                    return tokens[folderLevel - 1];
                                });
                                optionTooltips.push(tooltip);
                            }
                            dbfKeys.forEach((key, index) => {
                                optionTooltips.push([...new Set(shapefiles.map(shapefile => {
                                    return shapefile.geoJSON.features.map(feature => {
                                        if (!feature.properties) return '';
                                        return feature.properties[key] as string;
                                    }).flat();
                                }).flat())].filter(value => value !== '' && value !== undefined));
                            });
                            return <Card elevation={4} style={{ marginBottom: 10, padding: 15 }}>
                                <KeyGenerationSelector
                                    index={index + 1}
                                    options={[...folderOptions, ...dbfKeys]}
                                    inputFiles={shapefiles}
                                    setOutputFiles={(files, type) => {
                                        // console.log('setOutputFiles', type, files);
                                        setSelectableFiles((prevFiles => {
                                            const newFiles = { ...prevFiles };
                                            // console.log(newFiles);
                                            if (!newFiles[groupKey]) {
                                                newFiles[groupKey] = {
                                                    Points: [],
                                                    Boundary: [],
                                                    Zones: [],
                                                    Unknown: []
                                                };
                                            }
                                            newFiles[groupKey][type] = files;
                                            return newFiles;
                                        }));
                                    }}
                                    optionTooltips={optionTooltips}
                                />
                            </Card>
                        })}
                    </AccordionDetails>
                </Accordion>
            </Grid>}
        </Grid>
        {
            (jobData.length > 0) &&
            <JobImportTable
                jobs={jobData}
                updateJobs={(jobs) => { setJobData(jobs) }}
                deals={csvProps.selectedDeals}
                allDeals={csvProps.deals}
                setDrawerState={() => { }}
                setLoading={(task: string | boolean) => { setLoading(!!task) }}
                allOptions={flattenArrayObjects(Object.values(selectableFiles))}
                // allOptions={Object.keys(selectableFiles).length > 0 ? flattenObjects(Object.values(selectableFiles)) : { "Unknown" : originalShapefiles, "Zones": [], "Points": [], "Boundary": [] } as const}
                // pointsOptions={pointsFiles}
                // boundaryOptions={boundaryFiles}
                resetForm={() => {
                    setJobData([]);
                    setOriginalShapefiles([]);
                    setSelectableFiles({});
                    // setFields([]);
                    // setSelectedFields([]);
                    // setFarms([]);
                    // setSelectedGrowers([]);
                    // setSelectedDeals([]);
                    // setGrowers([]);
                    // setOriginalShapeFiles([]);
                    // setTransformedShapefiles([]);
                }}
            />
        }
    </>;
}

export const MemoizedCSVImportForm = React.memo(CSVImportForm);