import { Box, Button, TextField, Typography } from "@mui/material";
import React, { ChangeEvent } from "react";
import { getUser, registerUser } from "../api/airtable_ops";
import { useNavigate } from "react-router-dom";
import { LoadingComponent } from "./LoadingComponent";
import * as Sentry from '@sentry/react';

interface RegisterProps {
    setLoggedIn: (user: any) => void;
}

export default function Register(props: RegisterProps) {
    const [username, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [passwordError, setPasswordError] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [confirmPasswordError, setConfirmPasswordError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');

    const navigate = useNavigate();

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setLoading(true);
        if (emailError || passwordError || confirmPasswordError) {
            setLoading(false);
            return;
        }
        setError('');
        if (!username) {
            setEmailError('Email cannot be empty');
            setLoading(false);
            return;
        }
        if (!password) {
            setPasswordError('Password cannot be empty');
            setLoading(false);
            return;
        }

        if(!confirmPassword) {
            setConfirmPasswordError('Password cannot be empty');
            setLoading(false);
            return;
        }

        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match');
            setLoading(false);
            return;
        }

        try {
            const user = await registerUser({username, password});
            if (user) {
                const rogoPortalUser = await getUser();
                props.setLoggedIn(rogoPortalUser);
                navigate('/jobs/dashboard');
            }
        } catch (error: any) {
            Sentry.withScope(function (scope) {
              scope.setFingerprint(["custom-" + Date.now().toString()]);// forces Sentry to log each event as a separate issue, rather then grouping them together
              scope.setContext('Register', { username: username.toLowerCase() });
              var e = new RegisterError(error.message);
              scope.captureException(e, { mechanism: { handled: true } });
            });

            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    function handleEmailChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        //throw new Error("Function not implemented.");
        setEmailError('');
        // validate that username is an email with regex
        if (!emailPattern.test(event.target.value.toString())) {
            setEmailError("Invalid email address");
        }

        setEmail(event.target.value);
    }

    function handlePasswordChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        setPasswordError('');
        const password = event.target.value;
        if (password == '') {
            setPasswordError('Password cannot be empty')
            return;
        }

        if (password.length < 8) {
            setPasswordError('Password must be at least 8 characters');
            return;
        }


        setPassword(password);
        if (password != confirmPassword) {
            setConfirmPasswordError('Passwords do not match');
            return;
        }

    }

    function handleConfirmPasswordChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
        setConfirmPasswordError('');
        const confirmPassword = event.target.value;
        if (confirmPassword == '') {
            setConfirmPasswordError('Password cannot be empty')
            return;
        }

        if (confirmPassword.length < 8) {
            setConfirmPasswordError('Password must be at least 8 characters');
            return;
        }

        setConfirmPassword(password);
        if (confirmPassword != password) {
            setConfirmPasswordError('Passwords do not match');
            return;
        }
    }

    return (
        <Box
            maxWidth="md" margin="auto"
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
                mt: 1,
            }}
        >
            {(loading) && (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <LoadingComponent />
                </Box>
            )}
            <TextField
                variant="outlined"
                margin="normal"
                required
                error={!!emailError}
                helperText={emailError}
                disabled={loading}
                fullWidth
                id="emai"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                onBlur={handleEmailChange}
            />
            <TextField
                variant="outlined"
                margin="normal"
                error={!!passwordError}
                helperText={passwordError}
                required
                disabled={loading}
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                //value={password}
                onBlur={handlePasswordChange}
            />
            <TextField
                variant="outlined"
                margin="normal"
                error={!!confirmPasswordError}
                helperText={confirmPasswordError}
                required
                disabled={loading}
                fullWidth
                name="Confirm password"
                label="Confirm Password"
                type="password"
                id="Confirm password"
                autoComplete="confirm-password"
                //value={password}
                onBlur={handleConfirmPasswordChange}
            />
            {error &&
                <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                    style={{ color: 'red' }}
                >
                    {error}
                </Typography>}

            <Button type="submit" fullWidth variant="contained" color="primary" disabled={loading}>
                Register
            </Button>
        </Box>
    );
}

class RegisterError extends Error {
    constructor(message: string, public additionalInfo?: string) {
      super(message);
      this.name = 'RegisterError';
      
      Object.setPrototypeOf(this, new.target.prototype);
    }
  }