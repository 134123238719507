import { Box, Drawer, DrawerProps, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../hooks/UserContext";
import { AppVersion } from "./AppVersion";
import * as Sentry from '@sentry/react';

interface ItemBreakProps {
    style?: React.CSSProperties;
}

const ItemBreak = React.memo(function ItemBreak(props: ItemBreakProps) {
    return (
        <ListItem style={{ borderBottomStyle: 'solid', borderBottomColor: '#d3d3d3', borderBottomWidth: '1px', ...props.style }} />
    )
})

export type NavLink = {
    to: string;
    text: string;
}
interface DrawerNavigationProps extends DrawerProps {
    onNavigate: (link: NavLink) => void;
    setNavigationDrawerState: (open: boolean) => void;
}

export const DrawerNavigation = (props: DrawerNavigationProps) => {
    const navigate = useNavigate();
    const userContext = React.useContext(UserContext);
    const links: NavLink[] = []
    if (!userContext?.id) {
        links.push({ to: '/login', text: 'Login' });
    }
    // Your navigation links
    links.push(...[
        { to: '/jobs/dashboard', text: 'Jobs' },
        { to: '/jobs/submit', text: 'Submit Jobs' },
        { to: '/jobs/ready', text: 'Mark Jobs Ready' },
        // { to: '/jobs/import', text: 'Import Jobs' },
        // { to: '/jobs/import_process', text: 'Import Process' },
        { to: '/logout', text: 'Logout' }
    ]);

    const { setNavigationDrawerState, onNavigate, ...drawerProps } = props;
    return (
        <Drawer style={{ zIndex: 1000 }} anchor="left" variant="temporary" {...drawerProps} >
            <Box //style={{ marginTop: 80 }} 
                sx={{ width: 250 }}
                role="presentation"
                flexDirection={'column'}
            >
                <List>
                    {/* <ListItem key={'close'} disablePadding>
                        <ListItemButton onClick={() => props.setDrawerOpen(!props.open)}>
                            <ListItemText primary={'Close'} />
                        </ListItemButton>
                    </ListItem> */}
                    {/* <ListItem key={'close'} disablePadding onClick={() => props.setDrawerOpen(!props.open)}>
                        <ListItemText primary={'Close'} />
                    </ListItem> */}
                    {links.map((link) => (
                        <ListItem key={link.text} disablePadding>
                            <ListItemButton onClick={() => {
                                Sentry.metrics.set("user_view", userContext.id, {
                                    tags: {"page": link.to }
                                 });
                                props.onNavigate(link);
                                navigate(link.to, {
                                    state: { title: link.text }
                                })
                            }
                            }>
                                <ListItemText primary={link.text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                    <ItemBreak />
                    <ListItem key={'version'}>
                        <ListItemText primary={<AppVersion />} />
                    </ListItem>
                    <ListItem key={'host'}>
                        <ListItemText primary={window.location.host} />
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    );
};
